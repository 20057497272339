<template>
  <div class="apply person listen">
    <div class="add">
      <router-link :to="{
        path: `/lesson/completeInfo`
      }">
        <i class="jym-tianjia1"></i>添加听课人
      </router-link>
    </div>

    <div class="bar">
      听课人列表
      <span class="fr">左滑操作</span>
    </div>
    <div v-if="personList.length > 0">
      <div class="list" v-for="(item, index) in personList" :key="index">
        <div>
          <mt-cell-swipe
            :right="[
              {            
                content: '编辑',
                style: { background: '#333', color: '#fff', padding: '0 .5rem' },
                handler: () => edit(item.cert_id),
              },
              {            
                content: '删除',
                style: { background: '#f67a38', color: '#fff', padding: '0 .5rem' },
                handler: () => deleteSection(item.cert_id)
              }
            ]"
          >
            <p>
              <b>{{ item.name }}</b>
              {{ item.tel }}
            </p>
            <p>证件号：{{ item.cert_num }}</p>
          </mt-cell-swipe>
        </div>
      </div>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无上课人</p>
    </div>
  </div>
</template>

<script>
import { lessonCert, certDel } from "@api/public";
export default {
  name: "person-list",
  data() {
    return {
      personList: [],
      initLoading: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;

      lessonCert().then(res => {
        that.$set(
          that,
          "personList",
          res.data.filter(item => item.isdefault != 1)
        );
        that.initLoading = true
      });
    },

    // 编辑
    edit(cert_id) {
      this.$router.push({
        path: "/lesson/completeInfo",
        query: { cert_id: cert_id }
      });
    },
    // 删除
    deleteSection(cert_id) {
      certDel(cert_id).then(() => {
        this.$dialog.success("删除成功!");
        this.$router.go(0);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.person {
  .add {
    text-align: center;
    line-height: 0.8rem;
    height: 0.8rem;
    i {
      font-weight: 700;
      margin: 0 0.1rem;
    }
  }
  .bar {
    height: 0.6rem;
    line-height: 0.6rem;
    background: #f5f5f5;
    padding: 0 0.3rem;
    font-size: 0.24rem;
  }
  .list {
    padding-left: 0.3rem;
  }
}
</style>
